@tailwind base;
@tailwind components;
@tailwind utilities;

*:not(.text-white) > * {
    color: theme('colors.black');
}

body {
    background-color: theme('colors.pinkls');
}

input:disabled, button:disabled,
input:disabled:hover, button:disabled:hover {
    background-color: theme('colors.grayls');
    cursor: not-allowed;
}

input[type=range] {
    accent-color: theme('colors.pinkls');
}

.bg-sand{
    background-color: theme('colors.sand');
}

#chat-box {
    overflow: hidden;
    border-radius: 20px 0 0 20px;
}

#chat-message {
    padding-top: 1rem;
    overflow-y: scroll;
}

#chat-btn {
    width: 100%;
    height: 45px;
    display: block;
    border-radius: 20px 0px 0px 20px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    z-index: 2;
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

#chat-message header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 60px;
}

#chat-message header b {
    text-align: center;
    font-family: Avenir Next;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#chat-message header b,
#chat-message header em {
    text-align: left;
}

#chat-message header + p {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 85%;
}

.date {
    color: #C2C2C2;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#chat-message .message {
    padding-bottom: 1rem;
}